.label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    margin-bottom: 7px;
}

.field {
    color: #333f50 !important;
}

h2 {
    font-family: 'Roboto_Bd';
    font-size: 24px;
    margin-bottom: 28px;
    margin-top: 28px;
}

.platforms {
    margin-top: 28px;
}
