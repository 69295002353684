.summary {
    margin-bottom: 28px;

    .title {
        font-family: 'Roboto_Bd';
        font-size: 18px;
        margin-right: 16px;
    }
}

.navigationIcon {
    margin-left: 8px;
    vertical-align: middle;
}
