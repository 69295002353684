.files {
    padding-left: 28px;
    margin-top: 30px;
}

.files h4 {
    font-weight: 400;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.link {
    text-decoration: underline;
    margin-left: 6px;
}
