.summary {
    margin-bottom: 28px;
}

.summary .title {
    font-family: 'Roboto_Bd';
    font-size: 18px;
    margin-right: 16px;
}

.rightArrowButton {
    margin-right: -1rem;
}

.rightArrow {
    font-size: 14px;
}

.rightArrowIcon {
    width: 0.1rem;
    margin-left: -0.5rem;
}

.link {
    cursor: pointer;
}
