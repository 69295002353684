.selector {
    margin-bottom: 28px;
    display: flex;
    width: 100%;
}

.selector .hubs {
    width: 280px;
    margin-left: 28px;
    margin-right: 70px;
}

.selector .projects {
    flex: 1;
    min-width: 500px;
    margin-left: 28px;
    margin-right: 70px;
}

.selector .display {
    width: 150px;
    margin-left: 28px;
}
