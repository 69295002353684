.subtitle {
    font-weight: 600;
    font-size: 16px;
    margin-top: 28px;
}

.buttons {
    margin-top: 28px;
}

.notFound {
    color: #d32f2f;
}

.disabledRow {
    color: #bdbdbd;
}
