.failure h2,
.success h2 {
    font-family: 'Roboto_Bd';

    margin-bottom: 0;
}

.failure h2 {
    color: red;
}

.success h2 {
    color: green;
    margin-top: 14px;
}

.error {
    color: red;
}

.inProgress {
    color: orange;
}

.empty {
    margin-top: 160px;
    margin-bottom: 98px;
}

.buttons {
    text-align: center;
    margin-top: 28px;
}

.source {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #333f50;
    margin-right: 32px;
}

.sourceProject {
    color: #333f50;
    font-size: 14px;
    line-height: 1.5715;
    margin-right: 16px;
}

.sourceFolder {
    color: rgba(51, 63, 80, 0.45);
    font-size: 14px;
    line-height: 1.5715;
}
