.result h3 {
    font-family: 'Roboto-Bd';
    font-size: 35px;
    color: #b83f36;
}

.result h4 {
    color: #000;
    font-size: 16px;
}

.result .image {
    font-size: 329px;
}
