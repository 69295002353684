.main {
    margin-top: -12px;
    margin-left: -12px;
}

.content {
    color: rgba(0, 0, 0, 0.5);
    margin-left: -4px;
    margin-top: -16px;
    font-size: 13px;
}

.clear {
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
    font-size: 14px;
    line-height: normal;
    text-decoration: underline;
}
