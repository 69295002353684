h1 {
    font-size: 32px;
    //font-family: 'IBMPlexSans_Rg';
    margin: 0;
}

h1.title {
    font-family: 'Roboto_Bd';
    font-size: 24px;
    margin-bottom: 24px;
}

.hubs {
    margin-bottom: 48px;
    width: 100%;
}

h2 {
    font-size: 24px;
    //font-family: 'IBMPlexSans_Rg';
    margin: 0;
}

p {
    //font-family: 'IBMPlexSans_Md';
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.pageheader {
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.pageheader .boxwithhelpermessage {
    margin-top: 25px;
}

.pagebox {
    width: 100%;
    max-width: 1400px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 29px;
    font-family: 'Roboto_Rg';
}

.buttongroup {
    display: flex;
    align-items: center;
    gap: 9px;
}

.laststepbuttonbox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadbutton {
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
    //font-family: 'IBMPlexSans_Rg';
}

.loadbutton svg {
    fill: white;
}

.backbutton {
    display: flex;
    align-items: center;
    gap: 8px;
    //font-family: 'IBMPlexSans_Rg';
}

.connectionnamesbox {
    margin-bottom: 51px;

    //font-family: 'IBMPlexSans_Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    letter-spacing: 0.15px;
}

.modalbody {
    display: flex;
    justify-content: center;
}

.boxwithhelpermessage {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    margin-bottom: 29px;

    p {
        margin: 0;
        //font-family: 'IBMPlexSans_Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
    }
}

.boxwithhelpermessage .error {
    color: #d32f2f;
}

.grayborderbox {
    border: 1px solid #828282;
    width: 100%;
    border-radius: 0;
}

.linksformbox {
    min-height: 203px;
    padding: 12px 13px;
}

.uploadbox {
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 10px;

    padding: 16px;
    min-width: 500px;

    color: #828282;
}

.uploadform {
    position: relative;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.uploadform .helpertext {
    color: #00154b;
}

.uploadform .helpertext .underline {
    text-decoration: underline;
}

.steps {
    margin-bottom: 56px;
}

.fileuploadbox {
    margin-top: 6px;
    display: flex;
    gap: 58px;
    flex-wrap: wrap;
    align-items: center;

    input + label {
        cursor: pointer;
    }

    input:disabled + label {
        cursor: not-allowed;
    }
}

.uploadedfilename {
    //font-family: 'IBMPlexSans_Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-decoration: underline;
}

.hidden {
    display: none;
}

.dragactive {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.uploadhelpermessage {
    //font-family: 'IBMPlexSans_Rg';
    color: #333f50 !important;
    font-size: 14px;
}

.resultbox,
.summarybox {
    display: flex;
    flex-direction: column;
    gap: 42px;

    margin-bottom: calc(62px - 29px);
}

.resultlistbox {
    display: grid;
    gap: 8px;
}

.resultlistitem {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
}

.resultlistitem div {
    //font-family: 'IBMPlexSans_Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    letter-spacing: 0.15px;
}

.resultlistitem .error {
    color: #d32f2f;
    text-align: right;
}

.resultlistitem .success {
    color: green;
    text-align: right;
}

.resultlistitem .error p,
.resultlistitem .success p {
    margin: 0.25em;
}

.resultbox h2,
.summarybox h2 {
    font-family: 'Roboto_Bd';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */

    display: flex;
    align-items: center;
    letter-spacing: 0.15px;

    margin: 0;

    color: #00154b;
}

.summarybox table {
    //table-layout: auto;
    //width: 100%;
    thead {
        background: #f2f2f2;
        box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.12);
        border: 0;
    }
}

.summarybox table colgroup col {
    &:first-child {
        width: 20%;
    }

    &:nth-child(6),
    &:nth-child(7) {
        width: 25%;
    }
}

.bim360 {
    table colgroup col {
        &:first-child {
            width: 10%;
        }
        &:nth-child(4),
        &:nth-child(5) {
            width: 7%;
        }
        &:nth-child(6),
        &:nth-child(7) {
            width: 25%;
        }
    }
}

.summarybox table thead tr {
    font-size: 14px;
    text-transform: capitalize;
    color: #00154b;
    font-weight: 500;
    background: #fafafa;
}

.summarybox table tbody tr {
    color: rgba(0, 0, 0, 0.87);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
}

.summarybox table thead tr,
.summarybox table tbody tr {
    box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.12);
    vertical-align: middle;
}

.summarybox table .itembox {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    align-content: center;
    align-items: center;
    min-height: 88px;
}

.summarybox table tr .errormessage {
    //font-family: 'IBMPlexSans_Lt';
    color: #d32f2f;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 120%;
}

.summarybox table thead tr td,
.summarybox table tbody tr td {
    padding: 16px 12px;
}

.summarybox table thead tr .action,
.summarybox table tbody tr .action,
.summarybox table thead tr .edit,
.summarybox table tbody tr .edit {
    width: fit-content;
    vertical-align: middle;
    padding: 14px 16px;
}

.summarybox table thead tr .edit,
.summarybox table tbody tr .edit {
    padding: 0;
    min-width: 64px;
}

.summarybox table tbody tr .edit .iconbuttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span[role='img'] {
        color: rgba(0, 0, 0, 0.87);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
}

.createlinktag {
    width: fit-content;

    border: 1px solid #81a837;
    border-radius: 12px;
    font-family: 'Roboto_Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #81a837;
}
