.connection {
    .icon {
        font-size: 24px;
        vertical-align: middle;
        margin-right: 6px;
        color: green;
    }
    span {
        font-weight: 600;
    }
    button {
        margin-left: 24px;
    }
}
