.summary {
    margin-bottom: 28px;
}

.summary .titlebox {
    display: flex;
    align-items: center;
}

.summary .title {
    font-family: 'Roboto_Bd';
    font-size: 18px;
    margin-right: 16px;
}
