@import '~leaflet/dist/leaflet.css';

body {
    margin: 0;
    font-family: 'Roboto_Rg', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*** Fonts ***/

@font-face {
    font-family: 'AmazonEmber_Rg';
    src: local('AmazonEmber_Rg'), url('./resources/fonts/AmazonEmber_Rg.ttf') format('truetype');
}

@font-face {
    font-family: 'AmazonEmber_Bd';
    src: local('AmazonEmber_Bd'), url('./resources/fonts/AmazonEmber_Bd.ttf') format('truetype');
}

@font-face {
    font-family: 'AmazonEmber_Lt';
    src: local('AmazonEmber_Lt'), url('./resources/fonts/AmazonEmber_Lt.ttf') format('truetype');
}

@font-face {
    font-family: 'AmazonEmber_Md';
    src: local('AmazonEmber_Md'), url('./resources/fonts/AmazonEmber_Md.ttf') format('truetype');
}

@font-face {
    font-family: 'IBMPlexSans_Rg';
    src: local('IBMPlexSans_Rg'), url('./resources/fonts/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'IBMPlexSans_Bd';
    src: local('IBMPlexSans_Bd'), url('./resources/fonts/IBMPlexSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'IBMPlexSans_Lt';
    src: local('IBMPlexSans_Lt'), url('./resources/fonts/IBMPlexSans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'IBMPlexSans_Md';
    src: local('IBMPlexSans_Md'), url('./resources/fonts/IBMPlexSans-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto_Rg';
    src: local('Roboto_Rg'), url('./resources/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto_Bd';
    src: local('Roboto_Bd'), url('./resources/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto_Lt';
    src: local('Roboto_Lt'), url('./resources/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto_Md';
    src: local('Roboto_Md'), url('./resources/fonts/Roboto-Medium.ttf') format('truetype');
}

/*** ANTD styles ***/

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #0555E6 !important;
    border-color: #0555E6 !important;

    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
}

.ant-modal-title {
    font-family: 'Roboto_Rg';
    color: #0555E6;
    font-size: 20px;
}

.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-submenu .ant-menu-item-selected > a,
.ant-menu-submenu-selected .ant-menu-submenu-title {
    color: #ff9900 !important;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
    color: #bdbdbd !important;
}

.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > span > a {
    color: #000 !important;
}

.ant-menu-dark .ant-menu-item-selected > a:hover, .ant-menu-dark .ant-menu-item-selected > span > a:hover {
    color: #fff !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
    background: transparent !important;
    background-color: transparent !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: transparent !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover > span > a {
    color: #000 !important;
}


.ant-menu-dark .ant-menu-item-active > span > a {
    color: #000 !important;
}

.ant-carousel .slick-dots li button {
    background-color: #fff !important;
    height: 12px !important;
    width: 12px !important;
    border-radius: 6px !important;
    opacity: 1 !important;
}

.ant-carousel .slick-dots li.slick-active button {
    background-color: #000 !important;
}

.ant-btn-lg {
    border-radius: 4px !important;
}

.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}

.ant-statistic.space-around .ant-statistic-content-suffix {
    margin-left: 0;
}

.ant-tag {
    background-color: #fff !important;
    padding: 2px 12px !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #0555E6 !important;
    border-color: #0555E6 !important;
}

.ant-dropdown, .ant-dropdown-placement-bottomLeft > .ant-dropdown-menu, .ant-dropdown-menu-root, .ant-dropdown-menu-vertical, .ant-dropdown-menu-light {
    margin-top: 10px;
}

/*** Leaflet ***/

.leaflet-container .leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
    background: transparent;
}

.leaflet-container .leaflet-popup-content-wrapper, .leaflet-container  .leaflet-popup-tip {
    background-color: #000;
    color: #fff;
    font-family: 'Roboto_Bd';
}

.leaflet-container  a.leaflet-popup-close-button {
    color: #fff;
}


/*** Custom styles ***/

#root {
    height: 100%;
}

.link {
    cursor: pointer;
}

.buttons {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
}

.toolbar {
    display: flex;
    flex-direction: row;
    margin-bottom: 28px;
}

.toolbar .search {
    margin-right: 300px;
}

.toolbar.report {
    align-items: center;
}

.toolbar.report .title {
    margin-right: 300px;
    flex: 1;
}

.toolbar.report .title h2 {
    margin: 0;
}

.toolbar.report .title h1 {
    margin: 0;
}

.selector.report .ant-form-item-label{
    padding-bottom: 1px;
}

.primary-color {
    color: #0555E6 !important;
}

.primary-color-hover:hover {
    color: #0555E6 !important;
}
