.pageheader {
    display: flex;
    flex-direction: column;
    gap: 23px;

    hr {
        width: 100%;
    }
}

.pagebody {
    margin: 78px auto;
}

.select {
    display: flex;
    gap: 82px;
}

.selectformbox {
    display: flex;
    align-items: center;
    gap: 28px;
}

.selecthub .hubs,
.select .options {
    min-width: 280px;
}

.selecthub {
    display: flex;
    gap: 29px;
    align-items: center;
}

.searchbox {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.searchbox .importbuttongroup {
    display: flex;
    gap: 20px;
}

.searchbox .searchform {
    min-width: 65vw;
    width: 100%;
}
