.container {
    flex: 1 1;
}

.container .sidebar {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 25px;
    border-right: 1px solid #ccc;
    min-width: 0;
    overflow: hidden;
}

.container .main {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 25px;
    min-width: 0;
}
