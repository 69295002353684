.failure h2,
.success h2 {
    font-family: 'Roboto_Bd';

    margin-bottom: 0;
}

.failure h2 {
    color: red;
}

.failure .error {
    color: red;
}

.success h2 {
    color: green;
    margin-top: 14px;
}

.empty {
    margin-top: 160px;
    margin-bottom: 98px;
}

.buttons {
    text-align: center;
    margin-top: 28px;
}
