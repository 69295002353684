.summary {
    margin-bottom: 28px;
}

.summary .title {
    font-family: 'Roboto_Bd';
    font-size: 18px;
    margin-right: 16px;
}

.buttons {
    margin-left: 48px;
    .status {
        width: 300px;
    }
}

.table table {
    table-layout: fixed !important;
}
