.subheader {
    display: flex;
    align-items: center;
    padding-left: 36px;
    height: 48px;
    background-color: rgb(242, 242, 242);
    font-family: 'Roboto_Md';
    letter-spacing: 0.15008px;
    .menu {
        background: rgb(242, 242, 242) !important;
        width: 100%;
        align-items: center;
    }
}

.dropdown {
    display: flex;
    align-items: center;
    .dropdownText {
        width: 55px;
    }
}

.divider {
    width: 1px;
    height: 25px;
    background: #000;
    margin-left: 134px;
}

.toUppercase {
    text-transform: uppercase;
}

.dropdownItem {
    text-transform: uppercase;
    margin-right: 95px;
    margin-left: 2px;
    padding: 1px 0px;
}

.icon {
    font-size: 18px;
}
