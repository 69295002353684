.panel {
    margin-bottom: 48px;
    text-align: center;

    .stats {
        .title {
            color: #333f50 !important;
        }

        &.successful {
            span {
                color: green;
            }
        }

        &.failed {
            span {
                color: orange;
            }
        }

        &.error {
            span {
                color: red;
            }
        }
    }

    p {
        color: rgba(51, 63, 80, 0.45);
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 0;
    }

    .empty {
        height: 108.7px;
    }

    .button {
        text-align: center;
    }
}
