.result {
    .spin {
        display: inline-block;
        margin-top: 80px;
        margin-bottom: 80px;
    }

    h3 {
        margin-left: 24px;
        display: inline-block;
        font-family: 'Roboto-Bd';
        font-size: 35px;
    }

    h4 {
        color: #000;
        font-size: 16px;
    }

    .image {
        font-size: 240px;
    }
}
