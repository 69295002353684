h1.title {
    font-family: 'Roboto_Bd';
    font-size: 24px;
    margin-bottom: 24px;
}

.projects {
    width: 100%;
    margin-bottom: 48px;
}

.steps {
    margin-bottom: 56px;
}

h2 {
    font-family: 'Roboto_Bd';
    font-size: 19px;
}

.roles {
    width: 100%;
}

table.services {
    width: 100%;
    table-layout: fixed;
}

table.services tr {
    border-bottom: 1px solid rgb(217, 217, 217);
}

table.services th:first-child {
    width: 200px;
}

table.services th span {
    display: inline-block;
    width: 33%;
    text-align: center;
    font-weight: 400;
}

table.services td .item {
    margin-bottom: 0;
}

table.services td .item .radios {
    width: 100%;
}

table.services td .item .radios .radio {
    display: inline-block;
    width: 33%;
    text-align: center;
    margin: 0;
}

.buttons {
    margin-top: 28px;
}
