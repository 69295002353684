.tab {
    justify-content: flex-end;
    gap: 16px;
}

.tab > button {
    font-family: 'Roboto_Rg';
}

.copy {
    margin-left: 16px;
}
