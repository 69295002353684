.cellnex .ant-modal-title {
    font-family: 'Roboto_Rg';
    color: #4D8406;
    font-size: 20px;
}

.cellnex .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #4D8406 !important;
    border-color: #4D8406 !important;
}

.cellnex .primary-color {
    color: #4d8406 !important;
}

.cellnex .primary-color-hover:hover {
    color: #4d8406 !important;
}
